<template>
    <AppContainer>
        <stripeConnect/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import stripeConnect from '@/components/dashboard/stripe/stripeConnect.vue'
export default {
  components: {
    AppContainer,
    stripeConnect
  }
}
</script>