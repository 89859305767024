<template>

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 main_box">
                <img src="strip.png" alt="" class="img-fluid" width="100px">
                <p class="ml-2">Easily collect credit card payments with Stripe.</p>

                <hr>
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <h5 class="font-weight-bold">Stripe Connect</h5>
                    </div>
                    <div class="col-md-8">
                        <div class="stripe-connect-account"  v-show="!isStripeConnected">
                            <a href="https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://bravo.ondaq.com/api/connect_stripe&client_id=ca_DFpoVycgbaxpgTHHkSsLQqMDHOTLp4nn&state=kasd34haks23432dhkas&stripe_user[business_type]=individual"  target="_blank">
                                <img src="../../../assets/images/connect-stripe.png" alt="" class="img-fluid connect-stripe-img" width="190px">
                            </a>
                            <p class="mt-2 stripe-error" v-show="hasParam">{{errorMsg}}</p>
                            <!-- <p class="mt-2"> -->
                                <div class="row">
                                    <div class="col-12">
                                        <p class="mt-2">Online payments transactions are secured and processed with Stripe.</p>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <fa class="checkcircle" :icon="['fas', 'check']" />
                                        <span class=" ms-2 text-center">You have options to receive your money to your debit card or bank accounts</span>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <fa class="checkcircle" :icon="['fas', 'check']" />
                                        <span class="ms-2 text-center">We send your money out in the midnight</span>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <fa class="checkcircle" :icon="['fas', 'check']" />
                                        <span class=" ms-2 ">
                                            Any payments related issues or questions can be directed to Ondaq customer service agents.
                                            Email: support@ondaq.com
                                        </span>
                                    </div>
                                </div>
                            <!-- <p class="mt-2">Securely connect with Stripe to begin accepting payment <br> about connecting with Stripe.</p> -->
                        </div>
                        <!-- Test Key is  ca_DFpo1tRyECJ3lNo0OucGCPO4lf4AyAl9 -->
                        <div class="stripe-connected-account"  v-show="isStripeConnected">
                            <a class="btn btn-primary mt-0" target="_blank" href="https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://bravo.ondaq.com/api/connect_stripe&client_id=ca_DFpoVycgbaxpgTHHkSsLQqMDHOTLp4nn&state=kasd34haks23432dhkas&stripe_user[business_type]=individual">Update Account</a>
                            <p class="mt-2 stripe-success" v-show="hasParam">{{errorMsg}}</p>
                            <p class="mt-2"> A stripe connect account is already connected with your business. <br> You can update the stripe connect account by clicking the above button </p>
                        </div>

                    </div>
                </div>
                <hr>
                <div class="row" v-show="isStripeConnected">
                    <div class="col-md-4">
                        <h5 class="font-weight-bold">Stripe Account Status</h5>
                    </div>

                    <div class="col-md-8">
                        <div class="row justify-content-center align-items-center">
                            <!-- <div class="col-2">
                                <img src="pro.png" class="rounded-circle img-fluid" >
                            </div> -->
                            <div class="col-12 p-0" style="font-size: smaller;">
                                <span >{{StripeAccountStatus}}!</span>
                                <span class="text-muted">Your stripe connect account are good to recieve poyouts </span>
                            </div>
                            <!-- <div class="col-4 p-0 " style="font-size: smaller;">
                                <p >joind:Fri Dec 22 2022</p>
                            </div>
                            <div class="col-2 p-0">
                                <span><i class="fa fa-trash-o" style="font-size:25px;color:red"></i></span>
                            </div> -->
                        </div>
                        <!-- <hr>
                        <div class="row justify-content-center align-items-center">
                            <div class="col-2 ">
                                <img src="pro.png" class="rounded-circle img-fluid" >
                            </div>
                            <div class="col-4 p-0" style="font-size: smaller;">
                                <span> Muhammad Saif Ullah </span>
                                <span class="text-muted"> msaif@gmail.com </span>
                            </div>
                            <div class="col-4 p-0 m-0" style="font-size: smaller;">
                                <p>joind:Fri Dec 22 2022</p>
                            </div>
                            <div class="col-2 p-0">
                                <span><i class="fa fa-trash-o" style="font-size:25px;color:red"></i></span>
                            </div>
                        </div> -->

                    </div>
                </div>
            </div>
         </div>
    </div>
<!-- <div class="saleteam-area">
    <div class="teams" v-for="(employee,index) in employees" :key="index">
        <router-link :to="{name:'editEmployee',params:{id:employee.id}}" class="edit-member blue-circle">
            <img class="img-fluid" src="../../../assets/images/icons/pencil.svg" alt="">
        </router-link>
        <div class="delete-member blue-circle" v-on:click="deleteEmployee(employee.id)">
            <img class="img-fluid" src="../../../assets/images/icons/Delete.svg" alt="">
        </div>
        <div class="team-img">
            <img class="img-fluid" src="../../../assets/images/profile/profile.svg" alt="" />
        </div>
        <div class="team-title">
            <h3>{{employee.user.name}}</h3>

            <p v-if="employee.profession">{{employee.profession.title}}</p>
            <div class="member-status" :class="employee.status == 1 ? 'text-success':'text-danger'">
                <span v-if="employee.status == 1">Active</span>
                <span v-if="employee.status == 0">InActive</span>
            </div>
        </div>
        <div class="team-tags">
            <p>Active Days</p>
            <div class="permissions-section" >
                <ul>
                    <li v-for="hour in employee.employee_hours" :key="hour.id"><a href="#" v-if="hour.isOpen == 1">{{hour.title}}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="teams"  v-if="employees.length == 0">
        <div class="add-new d-block mt-5">
            <img src="../../../assets/images/employees/no-employee.svg" style="width: 125px">
            <h5 class="fw-bold">No Employees</h5>
            <p>Click icon to add an employee</p>
        </div>
    </div>
    <div class="teams">
        <div class="add-new">
            <router-link :to="{name:'addEmployee'}"><img class="img-fluid" src="../../../assets/images/icons/bigPlus.svg" alt="" /></router-link>
        </div>
    </div>
</div> -->
</template>

<script>
// import $ from 'jquery'
import axios from 'axios'
import { useToast } from "vue-toastification";
export default {
    setup () {
        const toast = useToast();
        return {toast}
    },
    data(){
		return{
            code: "",
           hasParam: false,
           isStripeConnected:false,
           StripeAccountStatus:""
		}
    },
    created() {
        this.checkStripeConnectAccount()
    },
    methods: {
        checkStripeConnectAccount(){
            let thiss = this
            axios.get('check_stripe_account').then(function (response) {
                if (response.data.status == 'success') {

                    if(response.data.data.connected > 0){
                        thiss.isStripeConnected = true;
                        thiss.StripeAccountStatus = response.data.data.status;
                    }else{
                        thiss.connect_stripe_account();
                    }

                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        connect_stripe_account(){
            let thiss = this;
            if(!thiss.isStripeConnected && typeof thiss.code !== "undefined"){
                axios.post('connect_stripe',{'code':thiss.code}).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.code = "";
                        thiss.isStripeConnected = true;
                        thiss.toast.success(response.data.message);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        thiss.toast.error(error.response.data.message);
                        console.log(error.response.data.message)
                    }
                });
            }
        }
    },
    mounted(){
        this.code = this.$route.query.code;
    }

}
</script>
<style scoped>

.main_box{
    width: 100%;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgb(49 81 161 / 8%);
    border-radius: 8px;
    padding: 15px 30px;
    margin-bottom: 25px;
}
.stripe-error{
    color: #eb6663;


}
.stripe-success{
    color: green;


}
.connect-stripe-img{
    width: 190px !important;
}
@media (max-width: 992px) {
  .container {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    width: 100%;
    max-width: none;
  }
}
</style>